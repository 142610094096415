.counterBody {
  height: 190px;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;


  .counter {
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: center;
  }
  .counterBtn, .counterBtn:hover {
    border: none;
    line-height: 50px;
    background: transparent;

    svg {
      width: 45px;
      height: 45px;
    }
  }
  .counterInput {
    text-align: center;
    border: none;
    background-color: #FFFFFF;
    font-size: 36px;
    width: 100px;
    height: 50px;
    border-radius: 4px;
  }
  .datePicker {
    display: flex;
    justify-content: center;
    width: auto;
    max-width: 400px;
    margin: 20px auto;
  }
}