.geoAddress {
  resize: none;
  border: solid 1px lightgrey;
  border-radius: 4px;
  width: 100%;
}
.autocomplete-dropdown-container {
  position: absolute !important;
  z-index: 1000;
  overflow: hidden;
  border-radius: 4px;
  border: #d8dbe0 1px solid;
  width: 90%;
  background-color: #fff;
}
.addressField {
  margin-top: 10px;
  font-size: 0.875rem;
  color: #768192;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
}

.addressField:focus {
  color: #768192;
  background-color: #fff;
  border-color: #958bef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #321fdb40;
}

.addressKeys {
  padding: 10px;
  border: #d8dbe0 1px solid;
}
.iconSize {
  width: 14px;
}
//**************** googleMap-v2 ********************
// #googleMap-v2 {
//   height: 30vh;
//   width: 100%;
//   margin-top: 60px;
//   button {
//     bottom: 0 !important;
//     top: calc(100% - 60px) !important;
//   }
// }
.markerV2 {
  font-size: 35px;
  color: red;
  top: calc(50% - 35px);
  left: 50%;
}
#btn_location {
  border-radius: 4px;
  border: 2px solid #e66158;
  background-color: #fff2f1;
  width: 100%;
  font-size: 14px;
  color: #eb7a72;
  align-items: center;
  justify-content: center;
  padding: 7px 14px;
  margin: 15px 0 0;
}
.separator {
  height: 1px;
  width: 100%;
  background-color: #d6d6d6;
  margin: 20px 0;
  position: relative;

  .separatorText {
    display: flex;
    position: absolute;
    top: -13px;
    left: 50%;
    margin-left: -15px;
    width: 25px;
    height: 25px;
    font-size: 10px;
    background: #fff;
    border: 0.5px solid #d4d4d4;
    align-items: center;
    justify-content: center;
    color: #d4d4d4;
    border-radius: 50%;
  }
}

.input-font-size {
  font-size: 16px !important;
}
.radio-other-details {
  position: absolute;
  margin-left: 25px;
  margin-top: 10px;
}
.other-details {
  position: absolute;
  margin-top: -25px;
}
.h1-name {
  position: absolute;
  margin-left: 24px;
  margin-top: 0px;
  font-size: 14px;
}

.mapBackground {
  background-color: #5f36e5;
  border-radius: 10px 10px 0 0;
  color: white;
}

.pac-container {
  z-index: 10000;
  // top: 460px !important;
  width: 300px !important;
  // left: 30px !important;
}

.pac-container,
.pac-item {
  // width: inherit !important;
}
